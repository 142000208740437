import {createSlice} from '@reduxjs/toolkit';

export interface GlobalState {
    isLoading: boolean
    loadingsCount: number
}

const initialState: GlobalState = {
    isLoading: false,
    loadingsCount: 0,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loadingStart: (state) => {
            state.loadingsCount += 1;
            state.isLoading = true;
        },
        loadingEnd: (state) => {
            if (state.loadingsCount > 0) {
                state.loadingsCount -= 1;
            }
            if (state.loadingsCount <= 0) {
                state.isLoading = false;
            }
        },
    },
});

export const {loadingStart, loadingEnd} = globalSlice.actions;

export default globalSlice.reducer;