export const colors = {
    primary: '#3F4B61',
    grey: '#605E5C',
    lightgrey: '#979797',
    text: '#212121',
    greyText: '#767676',
    blue: '#1D2951',
    white: '#FFF',

    // new colors
    peach: '#F2DFBA',
    sand: '#CDA57A',
    OffWhite: '#ECEADF',
    lavender: '#7786FC',
    navy: '#1D2951',
    background: '#F3F3F3',
    black: '#1E1E1E',
    gray: '#D9D9D9',
};