import React from 'react';
import {css} from '@emotion/css';
import {colors} from 'styles/colors';
import {HEADER_HEIGHT_REM} from 'config/global';

import logo from 'assets/icons/logo.png';

const PageHeader = () => {
    // const matchesScreenSize = useMediaQuery('(min-width:580px)');

    return <div className={styles.headerPrivate}>
        <img className={styles.logoImage} src={logo} alt='logo'/>
    </div>
            
    ;
};

const styles = {
    headerPrivate: css({
        width: '100%',
        height: HEADER_HEIGHT_REM,
        backgroundColor: colors.navy,
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
    }),
    logoImage: css({
        marginLeft: '1.25rem',
        height: '60px',
    }),
};

export default PageHeader;
