import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {surveyLocalization} from 'survey-core';
import 'survey-core/survey.i18n';
import {store} from 'store/store';

import App from './App';
import './index.css';

surveyLocalization.defaultLocale = 'da';
surveyLocalization.currentLocale = 'da';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
