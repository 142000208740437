import React from 'react';
import {css} from '@emotion/css';
import {colors} from 'styles/colors';

const Background = () => {
    return (
        <>
            <div className={styles.questionnariesBackground}></div>
        </>
    );
};

const styles = {
    questionnariesBackground: css({
        background: colors.OffWhite,
        width: '100%',
        position: 'fixed',
        height: '100%',
        zIndex: '-999999',
    }),
};

export default Background;
