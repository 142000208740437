import axios, {AxiosResponse} from 'axios';

axios.defaults.baseURL = 'https://survey-api.delphistudy.dk';
axios.defaults.headers.post['Content-Type'] = 'text/json';

export const QuestionnaireAPI = {
    uploadQuestionnaireCompleted: async (json: string): Promise<void> => {
        const response = await axios.post('/api/upload?code=9EmNVC6HUjlxAgX5tqDWWjsqSmTA_4GIt8NXfeYlfNjRAzFuNp1Tzw==',
            {
                surveyResult: json,
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            return badStatusError(response);
        }
    },
};

const badStatusError = (response: AxiosResponse) => {
    console.log('Response: ', response);
    throw new Error(`Invalid response: ${response.status} - ${response.data}`);
};