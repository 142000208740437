import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import en from 'date-fns/locale/en-GB';
import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';
import {css} from '@emotion/css';
import {ThemeProvider} from '@emotion/react';
import {CircularProgress, createTheme} from '@mui/material';
import {colors} from 'styles/colors';
import PageHeader from 'components/PageHeader';
import {RootState} from 'store/store';
import {useSelector} from 'react-redux';
import QuestionnairePage from 'pages/QuestionnairePage';
import {HEADER_HEIGHT_REM} from 'config/global';
import {questionnaireJSON} from 'config/questionnaire';
import Background from 'components/Background';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        text: {
            primary: colors.text,
        },
    },
    typography: {
        fontFamily: [
            'SuisseIntl-Regular',
            'regular',
        ].join(','),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '10px',
                    textTransform: 'none', 
                    fontSize: '18px',
                    color: 'white',
                },
                sizeSmall: {
                    height: '2rem',
                    fontSize: '16px',
                },
                sizeMedium: {
                    width: '15rem',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.primary,
                    lineHeight: '20px',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    marginTop: HEADER_HEIGHT_REM,
                },
            },
        },
    },
});

const App = () => {
    const isLoading = useSelector((state: RootState) => state.global.isLoading);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
                <div className={styles.content}>
                    <Background/>
                    <PageHeader/>
                    <Routes>
                        <Route path="/" element={
                            <QuestionnairePage json={JSON.stringify(questionnaireJSON)} />
                        }/>
                        <Route path="*" element={<Navigate to="/" replace />}/>
                    </Routes>
                </div>
                {isLoading && <div className={styles.globalDimmer}>
                    <CircularProgress/>
                </div>}
            </LocalizationProvider>
        </ThemeProvider>
    );
};

const styles = {
    content: css({
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }),
    globalDimmer: css({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex:'9999',
    }),
};

export default App;
