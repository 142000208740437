import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {css} from '@emotion/css';
import 'survey-core/defaultV2.min.css';
import {Survey} from 'survey-react-ui';
import {Model} from 'survey-core';
import * as SurveyCore from 'survey-core';
import {useDispatch} from 'react-redux';
import {Converter} from 'showdown';
import * as widgets from 'surveyjs-widgets';
import {nouislider} from 'surveyjs-widgets';
import {colors} from 'styles/colors';

import {light_theme_survey} from 'utils/theme_light_survey';
import {HEADER_HEIGHT_REM} from 'config/global';
import {QuestionnaireAPI} from 'utils/QuestionnaireAPI';

nouislider(SurveyCore);

widgets.ckeditor(SurveyCore);

type QuestionnairePageProps = {
    questionnaireId?: string
    json?: string
    version?: string
}
/* eslint-disable unused-imports/no-unused-vars */
const QuestionnairePage = ({json, questionnaireId, version}: QuestionnairePageProps) => {
    const dispatch = useDispatch();
    const [surveyJson, setSurveyJson] = useState<string | undefined>(json);
    
    const surveyComplete = useCallback(async (model: any) => {
        await QuestionnaireAPI.uploadQuestionnaireCompleted(model.data);
    }, []);

    useEffect(() => {
        (window as any).setSurveyJson = setSurveyJson;
        json && setSurveyJson(json);
    }, [json]);

    useEffect(() => {
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        localStorage.setItem('startQuestionnaireTime', currentTimeInSeconds.toString());
    }, [dispatch]);

    const survey = new Model(surveyJson);
    survey.applyTheme(light_theme_survey);
    survey.onComplete.add(surveyComplete);
    survey.locale = 'da';

    useMemo(() => {
        const converter = new Converter();
        survey.onTextMarkdown.add(function (_survey: any, options) {
            let str = converter.makeHtml(options.text);
            // eslint-disable-next-line eqeqeq
            if (str && str.indexOf('<p>') == 0) {
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
            }
            options.html = str;
        });
    }, [survey.onTextMarkdown]);

    

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Survey model={survey} />
            </div>
            <div className={styles.footer}>
                {/* <span className={styles.footerText}>
                    Version {version}
                </span> */}
            </div>
        </div>
    );
};

const styles = {
    container: css({
        background: colors.OffWhite,
        marginTop: HEADER_HEIGHT_REM,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        fontFamily: 'SuisseIntl-Regular !important',
        '.sd-page': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-header__text .sd-title': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-root-modern .sd-container-modern__title .sd-description': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-page .sd-page__description': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-root-modern': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-title.sd-element__title': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-item__control-label': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-completedpage h3, .sd-completed-before-page h3': {
            fontFamily: 'SuisseIntl-Regular !important',
        },
        '.sd-root-modern .sd-container-modern__title': {
            background: colors.OffWhite,
        },
        '.sd-root-modern__wrapper': {
            background: colors.OffWhite,
        },
        '.sd-body__navigation .sd-btn': {
            background: colors.navy,
            color: colors.white,
        },
        '.noUi-connect, .progress-bar': {
            backgroundColor:'rgb(29, 41, 81)',
        },
    }),
    content: css({
        flex: 1,
        width: '60vw',
        margin: '0 auto',
        display: 'flex',

        '@media (max-width: 800px)': {
            width: 'calc(100% - 2rem)',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
    }),
    footer: css({
        height: '1.5rem',
    }),
    footerText: css({
        color: '#605E5C',
        fontFamily: 'Segoe UI',
        fontSize: '0.8125rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '1.25rem',
        marginLeft: '5rem',
    }),
};

export default QuestionnairePage;
