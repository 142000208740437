type ImageFit = 'auto' | 'contain' | 'cover';
type ImageAttachment = 'fixed' | 'scroll';

interface IHeader {
  [key: string]: any;
}

interface ITheme {
  themeName?: string;
  colorPalette?: 'light' | 'dark';
  isPanelless?: boolean;
  backgroundImage?: string;
  backgroundImageFit?: ImageFit;
  backgroundImageAttachment?: ImageAttachment;
  backgroundOpacity?: number;
  header?: IHeader;
  cssVariables?: { [index: string]: string };
}

export const light_theme_survey: ITheme = {
    'backgroundImageFit': 'cover',
    'backgroundImageAttachment': 'scroll',
    'backgroundOpacity': 1,
    'themeName': 'sharp',
    'colorPalette': 'light',
    'isPanelless': false,
    'cssVariables': {
        '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
        '--sjs-general-backcolor-dark': 'rgba(228, 228, 228, 1)',
        '--sjs-general-backcolor-dim': '#ECEADF',
        '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
        '--sjs-general-backcolor-dim-dark': 'rgba(220, 220, 220, 1)',
        '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
        '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.6)',
        '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
        '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.6)',
        '--sjs-primary-backcolor': '#1D2951',
        '--sjs-primary-backcolor-light': 'rgba(29, 41, 81, 0.1)',
        '--sjs-primary-backcolor-dark': 'rgba(17, 24, 47, 1)',
        '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
        '--sjs-base-unit': '8px',
        '--sjs-corner-radius': '4px',
        '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
        '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
        '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
        '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
        '--sjs-shadow-small': '0px 0px 0px 0px rgba(33, 33, 33, 0)',
        '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
        '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
        '--sjs-shadow-inner': '0px 0px 0px 1px rgba(33, 33, 33, 0.25)',
        '--sjs-border-light': 'rgba(33, 33, 33, 0.25)',
        '--sjs-border-default': 'rgba(33, 33, 33, 0.25)',
        '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
        '--sjs-special-red': 'rgba(229, 10, 62, 1)',
        '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
        '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-green': 'rgba(25, 179, 148, 1)',
        '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
        '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
        '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
        '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
        '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
        '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
        '--sjs-article-font-xx-large-textDecoration': 'none',
        '--sjs-article-font-xx-large-fontWeight': '700',
        '--sjs-article-font-xx-large-fontStyle': 'normal',
        '--sjs-article-font-xx-large-fontStretch': 'normal',
        '--sjs-article-font-xx-large-letterSpacing': '0',
        '--sjs-article-font-xx-large-lineHeight': '64px',
        '--sjs-article-font-xx-large-paragraphIndent': '0px',
        '--sjs-article-font-xx-large-textCase': 'none',
        '--sjs-article-font-x-large-textDecoration': 'none',
        '--sjs-article-font-x-large-fontWeight': '700',
        '--sjs-article-font-x-large-fontStyle': 'normal',
        '--sjs-article-font-x-large-fontStretch': 'normal',
        '--sjs-article-font-x-large-letterSpacing': '0',
        '--sjs-article-font-x-large-lineHeight': '56px',
        '--sjs-article-font-x-large-paragraphIndent': '0px',
        '--sjs-article-font-x-large-textCase': 'none',
        '--sjs-article-font-large-textDecoration': 'none',
        '--sjs-article-font-large-fontWeight': '700',
        '--sjs-article-font-large-fontStyle': 'normal',
        '--sjs-article-font-large-fontStretch': 'normal',
        '--sjs-article-font-large-letterSpacing': '0',
        '--sjs-article-font-large-lineHeight': '40px',
        '--sjs-article-font-large-paragraphIndent': '0px',
        '--sjs-article-font-large-textCase': 'none',
        '--sjs-article-font-medium-textDecoration': 'none',
        '--sjs-article-font-medium-fontWeight': '700',
        '--sjs-article-font-medium-fontStyle': 'normal',
        '--sjs-article-font-medium-fontStretch': 'normal',
        '--sjs-article-font-medium-letterSpacing': '0',
        '--sjs-article-font-medium-lineHeight': '32px',
        '--sjs-article-font-medium-paragraphIndent': '0px',
        '--sjs-article-font-medium-textCase': 'none',
        '--sjs-article-font-default-textDecoration': 'none',
        '--sjs-article-font-default-fontWeight': '400',
        '--sjs-article-font-default-fontStyle': 'normal',
        '--sjs-article-font-default-fontStretch': 'normal',
        '--sjs-article-font-default-letterSpacing': '0',
        '--sjs-article-font-default-lineHeight': '28px',
        '--sjs-article-font-default-paragraphIndent': '0px',
        '--sjs-article-font-default-textCase': 'none',
        '--sjs-editor-background': 'rgba(255, 255, 255, 1)',
        '--sjs-editorpanel-hovercolor': 'rgba(220, 220, 220, 1)',
        '--sjs-font-questiontitle-weight': '400',
        '--sjs-font-questiontitle-family': 'Arial, sans-serif',
        '--sjs-font-questiondescription-color': 'rgba(33, 33, 33, 0.6)',
        '--sjs-font-questiontitle-color': 'rgba(33, 33, 33, 0.91)',
        '--sjs-font-editorfont-color': 'rgba(0, 0, 0, 1)',
        '--sjs-font-editorfont-placeholdercolor': 'rgba(0, 0, 0, 0.6)',
        '--sjs-question-background': 'rgba(255, 255, 255, 1)',
    },
};