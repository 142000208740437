export const questionnaireJSON = 
{
    'title': 'Hvordan kan vi forbedre os ?',
    'description': 'Vi vil gerne høre om din oplevelse efter at have været pilotdeltager i DELPHI studiet. Vi håber, at disse oplysninger kan hjælpe os med at finjustere processerne og føre til vellykket lancering. ',
    'pages': [
        {
            'name': 'page1',
            'elements': [
                {
                    'type': 'radiogroup',
                    'name': 'question1',
                    'title': 'Deltagerinformationen var vedhæftet i din invitation sendt til Digital Post. Læste du deltagerinformationen?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Ja',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Nej',
                        },
                    ],
                },
                {
                    'type': 'nouislider',
                    'name': 'question2',
                    'title': 'Oplysningerne på hjemmesiden var nyttige til at beslutte, om jeg havde lyst til at deltage eller ej.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question3',
                    'title': 'Det var nemt at tilmelde sig studiet og booke testdag 1 og testdag 2.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question4',
                    'title': 'Under informations samtalen blev jeg overrasket over hvad deltagelse i studiet indebar.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'I lav grad',
                        },
                        {
                            'value': '100',
                            'text': 'I høj grad',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'I lav grad',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'I høj grad',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question5',
                    'title': 'Efter informationssamtalen, følte jeg mig velinformeret omkring hvad jeg skulle igennem, og at jeg havde alle de nødvendige oplysninger om studiet. ',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question6',
                    'title': 'Testcentret er nemt at finde baseret på skiltningen fra Nørre Allé.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'comment',
                    'name': 'question7',
                    'title': 'Vil du være venlig at uddybe ovenstående svar, hvis du har yderligere kommentarer til tilmelding og information.',
                },
            ],
            'title': 'Tilmelding og informationsmøde',
            'description': 'Nedenstående spørgsmål handler om din oplevelse af rekrutterings processen til studiet.',
        },
        {
            'name': 'page2',
            'elements': [
                {
                    'type': 'nouislider',
                    'name': 'question8',
                    'title': 'Du modtog en mail dagen før din testdag 1. Var oplysningerne tilstrækkelige ift. at forberede sig på testdagen. Her tænkes f.eks. på faste og påklædning.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question9',
                    'title': 'Da du ankom til testdag 1 havde du et godt overblik over de procedurer og målinger, der ville finde sted på dagen. Du forstod overordnet hvad disse procedurer gik ud på.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question10',
                    'title': 'De oplysninger, jeg modtog til fælles undervisningen, var tilstrækkelige.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'checkbox',
                    'name': 'question11',
                    'title': 'Hvilken følelse sad du med i slutningen af fælles undervisningen? Du kan vælge flere muligheder. ',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Overvældet',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Optimistisk',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Frustreret',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Bekymret',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Træt',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Glad',
                        },
                        {
                            'value': 'Item 7',
                            'text': 'Nysgerrig efter at komme igang.',
                        },
                    ],
                },
                {
                    'type': 'nouislider',
                    'name': 'question12',
                    'title': 'Den samlede oplevelse ved testdag 1 var god',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'comment',
                    'name': 'question31',
                    'title': '<span style="font-size: 16px;">Vil du være venlig at uddybe ovenstående svar, hvis du har yderligere kommentarer til testdag 1.</span>',
                },
            ],
            'title': 'Testdag 1',
            'description': 'Nedenstående spørgsmål handler om din oplevelse af testdag 1.',
        },
        {
            'name': 'page3',
            'elements': [
                {
                    'type': 'nouislider',
                    'name': 'question13',
                    'title': 'Samlet set var hjemmeopgaverne nemme at fuldføre med de givne instruktioner og udleverede kits.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question14',
                    'title': 'DELPHI appen var brugervenlig, og den indeholdt alle de relevante oplysninger, der var nødvendige for at gennemføre de forskellige test og opgaver.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question15',
                    'title': 'Jeg følte mig overvældet af antallet af spørgeskemaer. ',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question16',
                    'title': 'Push-meddelelserne kom på meget dårlige tidspunkter, og jeg kunne ikke besvare de fleste af dem. Her tænkes på "Hvor stresset, træt og sulten er du"',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question17',
                    'title': 'Kostregistreringen udgør en stor byrde, og jeg ville foretrække færre antal dage hvor jeg skal kostregistrere.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question18',
                    'title': 'Kostregistrerings appen lykkes ofte med at finde den rigtige fødevare.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question19',
                    'title': 'Den skriftlige instruktion til måltidstestene var tilstrækkelige, så jeg nemt kunne udføre sukkerbelastnings- og måltidstesten. ',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'radiogroup',
                    'name': 'question20',
                    'title': 'Hvor udførte du måltidstesten?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Hjemme',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'På arbejdet',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Andet sted',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question21',
                    'title': 'Hvor udførte du sukkerbelastningstesten?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Hjemme',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'På arbejdet',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Andet sted',
                        },
                    ],
                },
                {
                    'type': 'nouislider',
                    'name': 'question22',
                    'title': 'Tasso blodprøvesæt var nemme at anvende?',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 96,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question23',
                    'title': 'Jeg havde ingen større problemer med Garmin-uret. ',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 96,
                    'tooltips': false,
                },
                {
                    'type': 'comment',
                    'name': 'question24',
                    'title': 'Hvor ofte måtte du lade Garmin uret op?',
                },
                {
                    'type': 'nouislider',
                    'name': 'question25',
                    'title': 'Jeg ville foretrække ikke at indsamle urin eller afføring.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 98,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question26',
                    'title': ' Kognitions spillene gav de nødvendige instruktioner for at kunne gennemføre dem.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question27',
                    'title': 'Døgnblodtryksmåleren var for meget.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question28',
                    'title': 'At deltage i studiet skabte ikke meget stress eller ændringer i mine vanlige rutiner.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question29',
                    'title': 'Jeg vidste, hvor jeg kunne søge om hjælp, hvis der opstod problemer i løbet af hjemmefasen.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'comment',
                    'name': 'question30',
                    'title': '<span style="font-size: 16px;">Vil du være venlig at uddybe ovenstående svar, hvis du har yderligere kommentarer til hjemmefasen.</span>',
                },
            ],
            'title': 'Hjemmefasen',
            'description': 'Nedenstående spørgsmål handler om din oplevelse, af de opgaver du skulle udføre derhjemme.',
        },
        {
            'name': 'page4',
            'elements': [
                {
                    'type': 'nouislider',
                    'name': 'question32',
                    'title': 'Du modtog en mail dagen før din testdag 2. Var oplysningerne tilstrækkelige ift. at forberede sig på testdagen? Her tænkes på f.eks. faste og påklædning.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question33',
                    'title': 'Da jeg ankom til testdag 2, havde jeg et godt overblik over de procedurer og målinger, der ville finde sted på dagen. Jeg forstod overordnet hvad disse procedurer gik ud på. ',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question34',
                    'title': 'Jeg ville foretrække ikke at udføre konditesten (cykeltesten)',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question35',
                    'title': 'Testdag 2 var for lang',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question36',
                    'title': 'Jeg forventede mere feedback på dagen fra forskerteamet vedrørende undersøgelserne og testene.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'nouislider',
                    'name': 'question37',
                    'title': 'Jeg forlod testdag 2 tilfreds med at have besluttet at deltage i studiet.',
                    'pipsValues': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        {
                            'value': '100',
                            'text': 'Enig',
                        },
                    ],
                    'pipsText': [
                        {
                            'value': 0,
                            'text': 'Uenig',
                        },
                        25,
                        50,
                        75,
                        {
                            'value': 100,
                            'text': 'Enig',
                        },
                    ],
                    'pipsDensity': 100,
                    'tooltips': false,
                },
                {
                    'type': 'comment',
                    'name': 'question38',
                    'title': '<span style="font-size: 16px;">Vil du være venlig at uddybe ovenstående svar, hvis du har yderligere kommentarer til testdag 2.</span>',
                },
            ],
            'title': 'Testdag 2',
            'description': 'Nedenstående spørgsmål handler om din oplevelse med testdag 2.',
        },
        {
            'name': 'page5',
            'elements': [
                {
                    'type': 'radiogroup',
                    'name': 'question39',
                    'title': 'Hvor let/svært var det at forstå anvisningerne til brug af Tasso?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Meget let at forstå',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Let at forstå',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Hverken let eller svært at forstå',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Svært at forstå',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Meget svært at forstå',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question40',
                    'title': 'Vurder venligst, hvordan du havde det med, at bruge Tasso-enheden til at tage blodprøven med.',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Meget selvsikker',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Lidt selvsikker - selvsikker',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Hverken selvsikker eller usikker',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Lidt usikker - Usikker',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Meget usikker',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question41',
                    'title': 'På en skala fra 0-10, hvordan vil du vurdere niveauet af smerte, da du trykke på knappen på Tasso-enheden, i forbindelse med blodprøven?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': '0 = ingen smerte',
                        },
                        {
                            'value': 'Item 2',
                            'text': '1 = meget mild smerte',
                        },
                        {
                            'value': 'Item 3',
                            'text': '2 = ubehagelig smerte',
                        },
                        {
                            'value': 'Item 4',
                            'text': '3 = tolerabel smerte',
                        },
                        {
                            'value': 'Item 5',
                            'text': '4 = foruroligende smerte',
                        },
                        {
                            'value': 'Item 6',
                            'text': '5 = meget foruroligende smerte',
                        },
                        {
                            'value': 'Item 7',
                            'text': '6 = intens smerte',
                        },
                        {
                            'value': 'Item 8',
                            'text': '7 = meget intens smerte',
                        },
                        {
                            'value': 'Item 9',
                            'text': '8 = fuldstændig forfærdelig smerte',
                        },
                        {
                            'value': 'Item 10',
                            'text': '9 = ulidelig smerte',
                        },
                        {
                            'value': 'Item 11',
                            'text': '10 = ufattelig smerte',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question42',
                    'title': 'Hvor overskueligt var det at bruge Tasso til at indsamle de fire blodprøver inden for kort tid?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Overskueligt',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Hverken overskueligt eller uoverskueligt',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Uoverskueligt',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question43',
                    'title': 'Under normale omstændigheder; hvilke af følgende udsagn ville gøre det mere overskueligt for dig at få taget en blodprøve?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Mindre smerte i forbindelse med blodprøven',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Ingen smerte i forbindelse med blodprøven',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Hvis det ikke krævede nåle',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Hvis det ikke forårsagede blå mærker',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Hvis de kunne tages hjemmefra',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Hvis der var større fleksibilitet ift. timingen af at skulle have taget en blodprøve.',
                        },
                        {
                            'value': 'Item 7',
                            'text': 'Ingen af ovenstående faktorer ville gøre det mere overskueligt at få taget en blodprøve.',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question44',
                    'title': 'Er du bange for nåle?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Ja',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Nej',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question45',
                    'title': 'Ville du være interesseret i at bruge Tasso-enheden til at tage en blodprøve derhjemme ordineret af sundhedsvæsnet?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Ja',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Nej',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question47',
                    'visibleIf': '{question45} = \'Item 1\'',
                    'title': 'Hvis "Ja": vælg den vigtigste årsag til, at "Ja" er svaret.',
                    'requiredIf': '{question45} = \'Item 1\'',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Jeg vil gerne have bekvemmeligheden ved at tage blodprøver hjemmefra',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Jeg stoler på min evne til at tage en blodprøve derhjemme',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Jeg vil gerne have en smertefri mulighed for at tage blodprøver derhjemme',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Jeg er interesseret i mit helbred',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Jeg ønsker flere løsninger på at kunne lave en hjemmetest',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Jeg vil gerne have privat livets fred og ro til at tage blodprøver ',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question46',
                    'visibleIf': '{question45} = \'Item 2\'',
                    'title': 'Hvis "nej": vælg den vigtigste årsag til, at "nej" er svaret.',
                    'requiredIf': '{question45} = \'Item 2\'',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Jeg stoler ikke på, at jeg selv kan tage en blodprøve',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Jeg tror ikke på, det ville være smertefrit',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Jeg stoler ikke på kvaliteten af blodprøver der tages derhjemme',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Jeg opfatter processen som for rodet til at tage en blodprøve derhjemme',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Jeg kan ikke lide at se mit eget blod',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Jeg ville have brug for hjælp til at tage en blodprøve derhjemme',
                        },
                        {
                            'value': 'Item 7',
                            'text': 'Det er ikke mit job, det er sundhedspersonales arbejde at tage blodprøver ',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question48',
                    'title': 'Hvis du kunne bruge Tasso-enheden til a tage en blodprøve derhjemme ordineret af sundhedsvæsenet, hvilke af følgende testresultater ville være mest interessante for dig.',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Kolesterol og fedt i blodet',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Lever sundhed',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Tidlige sygdomsmarkører',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Seksuelt overførte sygdomme',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Skjoldbruskkirtlens status',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Blodmangel',
                        },
                        {
                            'value': 'Item 7',
                            'text': 'Ernæringsmæssig sundhed',
                        },
                        {
                            'value': 'Item 8',
                            'text': 'Fødevareallergi',
                        },
                        {
                            'value': 'Item 9',
                            'text': 'Miljø allergi',
                        },
                        {
                            'value': 'Item 10',
                            'text': 'Gentest',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question49',
                    'title': 'Hvad er den maksimale afstand du vil køre for at få taget en blodprøve?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Mindre end 1 km',
                        },
                        {
                            'value': 'Item 2',
                            'text': '2-10 km',
                        },
                        {
                            'value': 'Item 3',
                            'text': '11-25 km',
                        },
                        {
                            'value': 'Item 4',
                            'text': '26-50 km',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Mere end 50 km',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question50',
                    'title': 'Vælg de tre vigtigste årsager til, hvorfor du måske undgår at få taget en blodprøve',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Smerter',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Frygt for nåle',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Det er ubelejligt at skulle hen til klinikken',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Tror ikke der er et medicinsk behov for en blodprøve',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Bivirkninger fra blodprøven (blå mærker, besvimelse)',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Jeg undgår aldrig at få taget en blodprøve',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question53',
                    'title': '<span style="font-size: 16px;">Har du nogensinde forsinket at gennemføre en blodprøve, der er ordineret af din sundhedsudbyder på grund af en eller flere af nedenstående årsager</span>',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Ja',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Nej',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question51',
                    'visibleIf': '{question53} allof [\'Item 1\']',
                    'title': '<span style="font-size: 16px;">Hvis "Ja" : vælg den vigtigste årsag til, at du forsinkede at gennemføre en ordineret blodprøve.</span>',
                    'requiredIf': '{question53} allof [\'Item 1\']',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Det var svært at planlægge tid',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Jeg glemte at gøre det',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Jeg følte ikke, at det var vigtigt/nødvendigt',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Jeg frygter nåle og selve blodprøven',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Det var ikke praktisk',
                        },
                        {
                            'value': 'Item 6',
                            'text': 'Jeg kan ikke lide eftervirkningerne af en blodprøve',
                        },
                        {
                            'value': 'Item 7',
                            'text': 'Andet',
                        },
                    ],
                },
                {
                    'type': 'checkbox',
                    'name': 'question52',
                    'visibleIf': '{question53} allof [\'Item 2\']',
                    'title': '<span style="font-size: 16px;">Hvis "Nej" : vælg den vigtigste årsag til, at du ikke har forsinket at gennemføre en ordineret blodprøve.</span>',
                    'requiredIf': '{question53} allof [\'Item 2\']',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Jeg følger altid sundhedspersonalets anvisninger',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Det er praktisk at udføre det',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Det er en vigtig del af min journal',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Blodprøveprocessen generer mig ikke',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Andet',
                        },
                    ],
                },
                {
                    'type': 'radiogroup',
                    'name': 'question54',
                    'title': 'Hvor sandsynligt er det, du vil anbefale Tasso til andre?',
                    'choices': [
                        {
                            'value': 'Item 1',
                            'text': 'Meget sandsynligt',
                        },
                        {
                            'value': 'Item 2',
                            'text': 'Sandsynligt',
                        },
                        {
                            'value': 'Item 3',
                            'text': 'Hverken sandsynligt eller usandsynligt',
                        },
                        {
                            'value': 'Item 4',
                            'text': 'Usandsynligt',
                        },
                        {
                            'value': 'Item 5',
                            'text': 'Meget usandsynligt',
                        },
                    ],
                },
            ],
            'title': 'Specifikke spørgsmål vedrørende Tasso',
            'description': 'Nedenstående spørgsmål omhandler brugervenligheden af Tasso, som du brugte i forbindelse med, at du tog blodprøver derhjemme. Tasso er en ny teknologi og derfor er det vigtigt for os og producenten, at få din feedback på produktet.',
        },
    ],
};